html {
    scroll-behavior: smooth;
}

body {
    background-color: rgb(113, 116, 128);
}

img.logo {
    height: 1em;
    margin-left: 1em;
    margin-top: auto;
    margin-bottom: auto;
}

.navbar-1 {
    display: inline-flex;
    width: 100%;
    padding: 8px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    transition: top 1s;
}

.navbar-1--hidden {
    display: inline-flex;
    top: -60px;
    width: 100%;
    position: sticky;
    left: 0;
    z-index: 1;
    transition: top 2.5s;
}

.image .skill-icon {
    height: 20%;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8%;
}

div.skill-column {
    padding: 5%;
}

.skill-content {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.blog-image-container {
    object-fit: cover;
}

img.blog-image {
    max-height: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    min-height: 25%;
    min-width: 25%;
}

.blog-columns {
    margin-left: 5%;
    margin-right: 5%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.blog-columns .blog-column {
    display: grid;
    margin: auto;
    position: relative;
    flex: 1;
    text-align: center;
    color: white;
    transition: .3s;
    max-width: 40%;
    height: 25vh;
    min-width: 10%;
    background-color: rgba(34, 34, 34, 0.016);
}

.blog-columns .blog-column:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-size: cover;
    z-index: -1;
}

.blog-columns .blog-column:hover {
    height: 30vh;
    transition: .5s;
    max-width: 40% !important;
    flex-grow: 2;
    cursor: pointer;
    color: #fff;
    background-color: rgba(0, 0, 255, 0.3);
}

.blog-columns .project.blog-column:hover {
    height: 30vh;
    transition: .5s;
    max-width: 40% !important;
    flex-grow: 2;
    cursor: pointer;
    color: #fff;
    background-color: rgba(252, 240, 178, 0.913);
}

.blog-columns .blog-column:hover h2 {
    transform: none;
    transition: .3s;
    visibility: visible;
}

.blog-columns .blog-title-container {
    margin: auto;
    font-size: 2em;
}

.blog-columns h2 {
    transform: translateY(10vh);
    visibility: hidden;
}

.is-first {
    color: rgb(178, 190, 252);
}

.is-first:hover {
    background-color: rgb(178, 190, 252);
    color: white;
}

.has-text-first {
    color: rgb(178, 190, 252);
}

.button.has-text-first:hover {
    background-color: rgb(178, 190, 252);
    color: white;
}

.is-second {
    color: rgb(232, 236, 255) !important;
}

.has-text-second {
    color: rgb(232, 236, 255) !important;
}

.is-third {
    color: rgb(178, 252, 240) !important;
}

.has-text-third {
    color: rgb(178, 252, 240) !important;
}

.is-fourth {
    background-color: rgb(113, 116, 128) !important;
}

.has-text-fourth {
    color: rgb(113, 116, 128) !important;
}

.is-fifth {
    color: rgb(0,0,0) !important;
}

.has-text-fifth {
    color: rgb(0, 0, 0) !important;
}

.is-sixth {
    color: rgb(128, 128, 128) !important;
    background-color: rgb(203, 203, 203);
}

.has-text-sixth {
    color: rgb(128, 128, 128) !important;
}

.is-white {
    background-color: white;
}

.has-content-centered {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5%;
}

.has-text-complementary {
    color: rgb(252, 240, 178) !important;
}

.has-space-around {
    margin: 5%;
}

.divider {
    margin-top: 1%;
    margin-bottom: 2%;
    padding: 1%;
}

.input:active {
    border-color: rgb(232, 236, 255);
    outline: rgb(178, 190, 252);
    box-shadow: inset 1em rgb(232, 236, 255);
}

.image-crop {
    width: 242px;
    height: 244px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.profile-pic {
    margin: 0 auto;
    width: auto;
}

.avatar-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.avatar-image {
    margin: 0 auto;
    width: auto;
}

.project-desc-image {
    max-width: 60%;
    max-height: 40%;
    margin-left: auto;
    margin-right: auto;
}

.social-icons {
    display: inline-flex;
    align-items: flex-end;
}

.social-icon {
    width: 2em;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: auto;
    margin-bottom: auto;
}

.icons--hidden {
    visibility: hidden;
}

.icons--visible {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

.fade-in-text {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

.fade-in-text-1 {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.shake {
    animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.contact-me {
    margin-left: 5%;
    margin-right: 5%;
}

.tile.is-child.box.blog-column {
    margin: auto !important
}